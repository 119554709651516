<template>
	<div class="about">
		<div class="banner"></div>
		<div class="c-width">
			<div class="address-box cf">
				<div class="qy-bj-item">
					<h3 class="gs-jj">公司简介</h3>
					<p class="jj-text">
						<span>北京矜群科技有限公司</span>（以下简称“矜群科技”）依托互联网和智能制造，全力打造一个共享制造服务平台，为中小微创企业、科研院所及个性化定制客户提供打样或小批量生产的非标准结构件的一站式托管服务，实现从产品设计、供应商选择、在线下单、产品加工以及质量验收和交付的全过程。
					</p>
					<p class="jj-text">
						矜群科技是年轻的新创企业，创始团队源自清华大学及国内资深技术专家。服务项目包括CNC、钣金、模型制作、3D打印、激光加工、线切割、压铸、注塑、铝挤、玻璃加工等常见加工工艺。
					</p>
					<p class="jj-text">
						平台严选认证了不同规模、具备各种加工及检验能力的供应商入驻，并协同设计、制造、供应链管理等优秀的专家团队，最优最快地支持和满足客户需求。
					</p>
				</div>
				<div class="gywm-list cf">
					<div class="gywm-item fl">
						<h3>SOLOGAN</h3>
						<p>矜群制造 无忧智造</p>
					</div>
					<div class="gywm-item fl">
						<h3>企业使命</h3>
						<p>与工厂携手，做强"智造"! </p>
						<p>和客户为伴，铸就"精品"!</p>
					</div>
					<div class="gywm-item fl">
						<h3>企业愿景</h3>
						<p>实现工厂制造和客户研发的数据精准拉通</p>
						<p>打造一个多方参与、全球共享、数字化的</p>
						<p style="text-align: left; margin-left: 5px;">制造服务平台</p>
					</div>
				</div>
				<!-- <div class="f-link">
					<div class="title">
						<img src="../../../assets/images/gywm_iocn_yqlj.png" alt="" srcset="" />
						<span>更多链接</span>
					</div>
					<div class="code-list cf">
						<div class="code-item fl">
							<img class="code-img" :src="gc_app.gc_app_code" alt="" />
							<p class="code-name">会员工厂电脑端</p>
						</div>
						<div class="code-item fl">
							<img class="code-img" :src="gc_wx.gc_wx_code" alt="" />
							<p class="code-name">会员工厂小程序</p>
						</div>
						<div class="code-item fl">
							<img class="code-img" :src="user_app.user_app_code" alt="" />
							<p class="code-name">用户小程序</p>
						</div>
						<div class="code-item fl">
							<img class="code-img" :src="user_wx.user_wx_code" alt="" />
							<p class="code-name">矜群客户小程序</p>
						</div>
					</div>
				</div> -->
				<div class="cf">
					<div class="l-box fl">
						<div class="map" id="map">
							<baidu-map ak="iOpI3TGtsFyUway1Or86dbnMhhuXbWmr" class="map" @ready="handler">
								<bm-marker :position="map" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
								</bm-marker>
							</baidu-map>
						</div>
						
					</div>
					<div class="r-box fl">
						<h6>{{ shop_name }}</h6>
						<div class="line"></div>
						<div class="add-item ">
							<span>地址：</span>
							<span>{{ shop_address }}</span>
						</div>
						<div class="add-item">
							<span>电话：</span>
							<span>{{ shop_mobile }}</span>
						</div>
						<div class="add-item">
							<span>邮箱：</span>
							<span>{{ shop_email }}</span>
						</div>
						<div class="line"></div>
						<div class="add-item">
							<span>声明协议：</span>
							<span @click="open">网站协议</span>
						</div>
						<div class="add-item">
							<span>备案号：</span>
							<span>{{ shop_beian }}</span>
						</div>
					</div>
				</div>
			
			
			</div>

		</div>
 <div class="alert-box" v-if="show">
      <!-- <div class="cf">
        <div class="fr close"  @click="close">X</div>
      </div> -->
      <!-- <h5>{{clause.content[index].title}}</h5> -->
      <div class="context" v-html="shop_xy"></div>
      <div class="close-btn" @click="close">阅读并同意该协议</div>
    </div>
	</div>
</template>
<script>
	import mixins from "../mixins";
	import BaiduMap from "vue-baidu-map/components/map/Map.vue";
	import BmMarker from "vue-baidu-map/components/overlays/Marker";
	export default {
		mixins,
		components: {
			BaiduMap,
			BmMarker,
		},
	};
</script>
<style scoped>
	@import "../about.css";
</style>
