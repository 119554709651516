export default {
    data: function () {
        return {
            map:{
                lng:116.356551,lat:39.929543
            },
            shop_address: "我的平台地址",
            shop_beian: "网站备案信息",
            shop_email: "公司邮箱",
            shop_mobile: 15251556262,
            shop_name: "共享制造管理系统",
            shop_statemen: "asdf",
            shop_xy: "网站协议",
            gc_app: {},
            gc_wx: {},
            user_app: {},
            user_wx: {},
            show:false
        }
    },
    created() {
        this.getAbout();
        this.getQrcode();
    },
    methods: {
        open:function(){
            this.show = true
        },
        close:function(){
            this.show = false
        },
        handler: function ({BMap,map}) {
            console.log(BMap,map,123123)
            this.$post('/api/base/abountUs.html', {}).then((res) => {
                if (res.code == 200) {
                    var  lng = res.data.longitude;
                    var lat = res.data.Latitude;
                    this.map.lng  =lat ;
                    this.map.lat = lng;
                    // this.point = new BMap.Point(116.356551,39.929543);
                    this.point = new BMap.Point(lat,lng);
                    map.centerAndZoom(this.point,15);
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        getAbout: function () {
            this.$post('/api/base/abountUs.html', {}).then((res) => {
                if (res.code == 200) {
                 
                    this.shop_address = res.data.shop_address || '';
                    this.shop_beian = res.data.shop_beian || '';
                    this.shop_email = res.data.shop_email || '';
                    this.shop_mobile = res.data.shop_mobile || '';
                    this.shop_name = res.data.shop_name || '';
                    this.shop_statemen = res.data.shop_statemen || '';
                    this.shop_xy = res.data.shop_xy || ''
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        getQrcode: function () {
            this.$post('/api/index/getQrcode.html', {}).then((res) => {
                if (res.code == 200) {
                    this.gc_app = res.data.gc_app;
                    this.gc_wx = res.data.gc_wx;
                    this.user_app = res.data.user_app;
                    this.user_wx = res.data.user_wx
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    }
}